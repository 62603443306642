import { production } from '../utils/Environments'

export const FACEBOOK_APP_ID = production ? '' : ''

export const GOOGLE_ANALYTICS_ID = production ? 'G-F7EKVFB0RQ' : 'G-JPB3VHX7EN'

export const SENTRY_DSN =
  'https://8edd019bb42a4658ac67bed8c03f90c2@o224195.ingest.sentry.io/5304367'

export const API_URL = `https://graphql.contentful.com/content/v1/spaces/${process.env.NEXT_PUBLIC_CONTENTFUL_SPACE_ID}/`

// トップページにて表示するニュースの件数
export const TOP_NEWS_LIMIT = 5
// ニュース一覧にて表示するニュースの件数
export const NEWSLIST_NEWS_LIMIT = 10

// トップページにて表示する導入事例の件数
export const TOP_CASESTUDY_LIMIT = 3
// 導入事例一覧にて表示する導入事例の件数
export const CASESTUDYLIST_CASESTUDY_LIMIT = 9
