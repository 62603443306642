import localfont from 'next/font/local' // eslint-disable-line
import '../styles/global.css'

import { ApolloProvider } from '@apollo/client'
import * as Sentry from '@sentry/browser'
import App, { AppProps } from 'next/app'
import Head from 'next/head'
import Router from 'next/router'
import React from 'react'

import { SENTRY_DSN } from '../config/settings'
import adobeLoader from '../utils/AdobeLoader'
import Analytics from '../utils/Analytics'
import { useApollo } from '../utils/ApolloClient'

const NotoSansJP = localfont({
  src: [
    {
      path: '../fonts/Noto_Sans_JP/NotoSansJP-Regular.ttf',
      weight: '400',
    },
    {
      path: '../fonts/Noto_Sans_JP/NotoSansJP-Medium.ttf',
      weight: '500',
    },
    {
      path: '../fonts/Noto_Sans_JP/NotoSansJP-Bold.ttf',
      weight: '700',
    },
  ],
  display: 'swap',
  variable: '--noto-sans-jp',
  fallback: ['system-ui', 'arial'],
})
const Overpass = localfont({
  src: [
    {
      path: '../fonts/Overpass/Overpass-Bold.ttf',
      weight: '700',
    },
    {
      path: '../fonts/Overpass/Overpass-Black.ttf',
      weight: '900',
    },
  ],
  display: 'swap',
  variable: '--overpass',
  fallback: ['system-ui', 'arial'],
})

Sentry.init({
  dsn: SENTRY_DSN,
  environment: process.env.NODE_ENV,
})

class RydeIncWebApp extends App {
  public componentDidCatch(error: Error, errorInfo: any) {
    Sentry.withScope((scope) => {
      scope.setExtras(errorInfo)
      Sentry.captureException(error)
    })
    // super.componentDidCatch(error, errorInfo)
  }

  public componentDidMount() {
    Analytics.init()

    const { asPath } = this.props.router
    if (asPath) Analytics.logPageView(asPath)

    Router.events.on('routeChangeStart', (url) => {
      Analytics.logPageView(url)
    })

    adobeLoader.init()
  }

  render() {
    const { router, Component, pageProps } = this.props

    return (
      <RydeIncWeb router={router} Component={Component} pageProps={pageProps} />
    )
  }
}

const RydeIncWeb: React.FC<AppProps> = ({ Component, pageProps }) => {
  const apolloClient = useApollo(pageProps.initialApolloState)
  return (
    <ApolloProvider client={apolloClient}>
      <>
        <Head>
          <link rel="shortcut icon" href="/favicon.ico" />
        </Head>
        <style
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: `
            :root {
            --font-noto-sans-jp: ${NotoSansJP.style.fontFamily};
            --font-overpass: ${Overpass.style.fontFamily};
            }`,
          }}
        />
        <Component {...pageProps} />
      </>
    </ApolloProvider>
  )
}

export default RydeIncWebApp
